import { File } from '@/interfaces/file.interface';
import { SearchState } from '@/interfaces/states/search-state.interface';
import alphaSort from '@/utils/alphabetic-sort';

export default {
  items: (state: SearchState): File[] => {
    const { items } = state;
    return items.sort(({ title }, { title: title2 }) => alphaSort(title, title2));
  },

  loading: (state: SearchState): boolean => state.loading,

  keyword: (state: SearchState): string => state.keyword,
};
