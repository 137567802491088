import { File } from '@/interfaces/file.interface';
import { FavoriteState } from '@/interfaces/states/favorite-state.interface';
import alphaSort from '@/utils/alphabetic-sort';

export default {
  items: (state: FavoriteState): File[] => {
    const { items } = state;
    return items.sort(({ title }, { title: title2 }) => alphaSort(title, title2));
  },

  loading: (state: FavoriteState): boolean => state.loading,

  // eslint-disable-next-line arrow-body-style
  isFavorite: (state: FavoriteState): ((id: string) => boolean) => {
    return (id: string): boolean => state.items.some((item) => item.id === id);
  },
};
