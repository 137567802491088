


















import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'SyncViewDialog',
  props: {
    value: Boolean as PropType<boolean>,
  },
  computed: {
    ...mapGetters('sync', ['errors']),

    show: {
      set(value: boolean) {
        this.$emit('input', value);
      },
      get(): boolean {
        return this.value;
      },
    },
  },

  methods: {
    hideDialog() {
      this.$emit('hide');
    },
  },
});
