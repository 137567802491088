













import Vue from 'vue';

export default Vue.extend({
  name: 'BaseSubmitButton',

  props: {
    title: String,
    loading: Boolean,
    disabled: Boolean,
  },
});
