import { Category } from '@/interfaces/category.interface';
import { File } from '@/interfaces/file.interface';
import { CategoryState } from '@/interfaces/states/category-state.interface';
import alphaSort from '@/utils/alphabetic-sort';

export default {
  items: (state: CategoryState): Category[] => state.items,

  loading: (state: CategoryState): boolean => state.loading,

  rootCategories: (state: CategoryState): Category[] => {
    const { items } = state;
    return items
      .filter((item: Category) => item.parentIds.length === 0)
      .sort(({ title, positions }, { title: title2, positions: positions2 }) => {
        const pos1 = positions?.find((p) => !p.parentCategoryId)?.position?.toString() ?? 0;
        const pos2 = positions2?.find((p) => !p.parentCategoryId)?.position?.toString() ?? 0;

        const positionSort = alphaSort(pos1, pos2);
        const titleSort = alphaSort(title, title2);

        return positionSort || titleSort;
      });
  },

  subCategories: (state: CategoryState): ((id: string) => Category[]) => {
    const { items } = state;
    return (id: string): Category[] =>
      // eslint-disable-next-line implicit-arrow-linebreak
      items
        .filter((item) => item.parentIds.includes(id))
        .sort(({ title, positions }, { title: title2, positions: positions2 }) => {
          const pos1 =
            positions?.find((p) => p.parentCategoryId === +id)?.position?.toString() ?? 0;
          const pos2 =
            positions2?.find((p) => p.parentCategoryId === +id)?.position?.toString() ?? 0;

          const positionSort = alphaSort(pos1, pos2);
          const titleSort = alphaSort(title, title2);

          return positionSort || titleSort;
        });
  },

  categoryFiles: (state: CategoryState): File[] => {
    const { categoryFiles } = state;
    return categoryFiles.sort(({ title, position }, { title: title2, position: position2 }) => {
      const positionSort = alphaSort(position, position2);
      const titleSort = alphaSort(title, title2);

      return positionSort || titleSort;
    });
  },

  level: (state: CategoryState): number => state.level,
};
