











































import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { mapActions, mapGetters } from 'vuex';
import SwUpdateMixin from '@/mixins/sw-update';
import TheMenu from '@/components/TheMenu.vue';
import { NotificationActions } from '@/store/notification/actions.enum';

if (process.env.VUE_APP_SENTRY) {
  Sentry.init({
    Vue,
    dsn:
      'https://8c796badfc3a447cb36792a1b0b61db1@o359776.ingest.sentry.io/5551596',
    autoSessionTracking: true,
    logErrors: true,
    integrations: [new Integrations.BrowserTracing()],
    tracingOptions: {
      trackComponents: true,
    },

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_SENTRY_ENV,
  });

  Sentry.setContext('app', {
    version: process.env.PACKAGE_VERSION,
  });
}

export default Vue.extend({
  name: 'App',

  mixins: [SwUpdateMixin],

  components: { TheMenu },

  data() {
    return {
      showErrorAlert: false,
      showSuccessAlert: false,
      timeout: null as ReturnType<typeof setTimeout> | null,
    };
  },

  computed: {
    ...mapGetters('notification', {
      errorText: 'error',
      successText: 'success',
    }),
  },

  watch: {
    errorText(newValue) {
      if (newValue === null) {
        return;
      }

      if (this.timeout) clearTimeout(this.timeout);

      this.showSuccessAlert = false;
      this.showErrorAlert = true;

      this.timeout = setTimeout(() => {
        this.showErrorAlert = false;
        this.setErrorText(null);
      }, 5000);
    },
    successText(newValue) {
      if (newValue === null) {
        return;
      }

      if (this.timeout) clearTimeout(this.timeout);

      this.showErrorAlert = false;
      this.showSuccessAlert = true;

      this.timeout = setTimeout(() => {
        this.showSuccessAlert = false;
        this.setSuccessText(null);
      }, 5000);
    },
  },

  created() {
    this.$i18n.locale = navigator.language.substring(0, 2) ?? 'en';
  },

  methods: {
    ...mapActions('notification', {
      setErrorText: NotificationActions.SET_ERROR,
      setSuccessText: NotificationActions.SET_SUCCESS,
    }),
  },
});
