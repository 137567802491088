import { File } from '@/interfaces/file.interface';
import { FileState } from '@/interfaces/states/file-state.interface';
import alphaSort from '@/utils/alphabetic-sort';

export default {
  highlights: (state: FileState): File[] => {
    const { highlights } = state;
    return highlights.sort(({ title, position }, { title: title2, position: position2 }) => {
      const positionSort = alphaSort(position, position2);
      const titleSort = alphaSort(title, title2);

      return positionSort || titleSort;
    });
  },
  lastUploaded: (state: FileState): File[] => state.lastUploaded,
  loading: (state: FileState): boolean => state.loading,
  showHighlights: (state: FileState): boolean => state.showHighlights,
};
