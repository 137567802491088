





















import Vue from 'vue';
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import i18n from '@/i18n';

extend('required', {
  ...required,
  message: i18n.t('validate.required').toString(),
});

export default Vue.extend({
  name: 'BaseInputField',

  components: {
    ValidationProvider,
  },

  props: {
    type: { type: String, default: 'text' },
    value: { type: String, default: '' },
    label: String,
    name: String,
    icon: String,
  },

  methods: {
    updateInputValue(value: string) {
      this.$emit('input', value);
    },
  },
});
